import React from 'react'

export const BlendedTextImageForeground = () => {
  return (
    <svg
      width="2560"
      height="933"
      viewBox="0 0 2560 933"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1125_119)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2576.89 0.882812C2576.89 0.882812 2341.46 101.367 2175.11 158.83C1831.54 277.516 1608.18 324.299 1213.33 368.589C966.862 396.236 823.696 402.387 572.444 400.782C349.531 399.358 -17.7778 362.552 -17.7778 362.552L-16.2219 481.419C-17.2499 481.317 -17.7778 481.265 -17.7778 481.265L-16.4977 579.059L-143 1001.5L1264 1027L2671 1001.5L2576.89 277.191V119.594V0.882812Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1125_119">
          <rect width="2560" height="933" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
