import React from 'react'

export const BlendedTextImageBackground = () => {
  return (
    <svg
      width="2560"
      height="770"
      viewBox="0 0 2560 770"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M2576.89 0.882812C2576.89 0.882812 2341.46 101.367 2175.11 158.83C1831.54 277.516 1608.18 324.299 1213.33 368.589C966.862 396.236 823.696 402.387 572.444 400.782C349.531 399.358 -17.7778 362.552 -17.7778 362.552L-14.2223 634.182H1752V459L2560 304.5V634.182H2576.89V0.882812Z"
          fill="#E5E0D9"
        />
        <path
          d="M2576.89 119.594C2576.89 119.594 2341.46 220.079 2175.11 277.542C1831.54 396.228 1608.18 443.011 1213.33 487.301C966.862 514.949 823.696 521.1 572.444 519.494C349.531 518.07 -17.7778 481.265 -17.7778 481.265L-14.7583 711.946C20.754 715.449 361.346 748.528 572.444 749.876C823.696 751.482 966.862 745.331 1213.33 717.683C1608.18 673.393 1831.54 626.611 2175.11 507.924C2341.46 450.462 2576.89 349.977 2576.89 349.977V119.594Z"
          fill="#DCD7D0"
        />
      </g>
      <defs>
        <clipPath id="clip0_1125_100">
          <rect width="2560" height="770" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
