import React from 'react'

import { globalClickHandler } from '@/helpers/globalClickHandler'
import {
  Body,
  Button,
  COLORS,
  CloudinaryImage,
  Layout,
  TitleLarge,
  TitleSmall,
} from 'ethos-design-system'
import { EVENT_NAMES } from 'lib/@getethos/analytics/constants'

import styles from './BlendedTextImage.module.scss'
import { BlendedTextImageBackground } from './BlendedTextImageBackground'
import { BlendedTextImageForeground } from './BlendedTextImageForeground'

interface BlendedTextImageProps {
  moduleData: {
    eyebrow: string
    heading: string
    subheading: string
    buttonText: string
    buttonUrl: string
    newTab: boolean
    groupImage: string
    imageAlt: string
  }
}

export const BlendedTextImage = ({ moduleData }: BlendedTextImageProps) => {
  const {
    eyebrow,
    heading,
    subheading,
    buttonText,
    buttonUrl,
    newTab,
    groupImage,
    imageAlt,
  } = moduleData

  let utmSource: string | null = 'none'
  if (typeof window !== 'undefined') {
    const urlSearchParams = new URLSearchParams(window.location.search)
    if (urlSearchParams.has('utm_source')) {
      utmSource = urlSearchParams.get('utm_source')
    }
  }

  const handleCtaClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const analyticsEvent = {
      name: EVENT_NAMES.CTA_CLICKED,
      properties: {
        module: 'Blended Text Image',
        ctaLabel: buttonText,
        clickthroughUrl: buttonUrl,
        utmSource: utmSource,
      },
    }
    globalClickHandler({
      event,
      analyticsEvent,
      urlProps: {
        url: buttonUrl,
        target: newTab ? '_blank' : '_self',
      },
    })
  }

  return (
    <div className={styles.componentWrapper}>
      <Layout.HorizontallyPaddedContainer className={styles.layoutContainer}>
        <div className={styles.contentContainer}>
          <div className={styles.eyebrow}>
            <Body.Medium500 color={COLORS.BRAND_FOREST}>
              {eyebrow}
            </Body.Medium500>
          </div>
          <div className={styles.heading}>
            <TitleLarge.Sans.Medium500>{heading}</TitleLarge.Sans.Medium500>
          </div>
          <div className={styles.subheading}>
            <TitleSmall.Sans.Regular400>
              {subheading}
            </TitleSmall.Sans.Regular400>
          </div>
          <div className={styles.ctaWrapper}>
            <Button.Medium.Black fullWidth onClick={handleCtaClick}>
              {buttonText}
            </Button.Medium.Black>
          </div>
        </div>
        <div className={styles.imageContainer}>
          <div className={styles.imageWrapper}>
            <CloudinaryImage
              width={[350, 300, 430, 468]}
              height={[712, 610, 879, 951]}
              publicId={groupImage}
              alt={imageAlt}
            />
          </div>
        </div>
      </Layout.HorizontallyPaddedContainer>
      <div className={styles.background}>
        <BlendedTextImageBackground />
      </div>
      <div className={styles.foreground}>
        <BlendedTextImageForeground />
      </div>
    </div>
  )
}

export default BlendedTextImage
